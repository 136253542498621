import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Multiselect } from "multiselect-react-dropdown";
import { ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { API_URL } from "../../utils/admin-api-url";
import Header from "../CommonTemplates/Header.js";
import Sidebar from "../CommonTemplates/SideBar.js";
import { getCategories, getSubCatList } from "../../_actions/catActions.js";
import { getActiveTaxes } from "../../_actions/taxActions.js";
import {
  getProduct,
  updateProduct,
  uploadProductImage,
  deleteProductImage,
} from "../../_actions/productActions.js";
import { getBrands } from "../../_actions/brandActions.js";

class EditProduct extends Component {
  state = {
    readOnly: true,
    fields: {},
    errors: {},
    form_data: {},
    productImage: "",
    multiSelectCategories: [],
    selectedValue: [],
    cat_ids: [],
    images: [],
  };

  async componentDidMount() {
    const id = this.props.match.params.id;
    await this.props.getSubCatList();
    await this.props.getCategories();
    await this.props.getBrands();
    await this.props.getActiveTaxes();
    await this.props.getProduct(id);
    var productData = this.props.products.product[0];
    var fields = this.state.fields;
    Object.keys(productData).forEach(function eachKey(key) {
      fields[key] = productData[key];
    });
    this.setState(fields);
    var cat_ids = fields["cat_id"].split(",");
    this.setState({ cat_ids: cat_ids });
    const selectedValues = [];
    for (let i = 0; i < this.state.cat_ids.length; i++) {
      for (let j = 0; j < this.props.categories.subCats.length; j++) {
        if (this.props.categories.subCats[j]["id"] == this.state.cat_ids[i]) {
          selectedValues.push(this.props.categories.subCats[j]);
        }
      }
    }
    this.setState({ multiSelectCategories: selectedValues });
    this.setState({ selectedValue: selectedValues });
  }

  // Input handle
  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState(fields);
  };

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Please enter product name.";
    } else if (!fields["name"].match(/^[a-zA-Z0-9 ]+$/)) {
      formIsValid = false;
      errors["name"] = "Please enter valid product name.";
    }
    if (!fields["description"]) {
      formIsValid = false;
      errors["description"] = "Please enter product description.";
    }
    if (!fields["cat_id"]) {
      formIsValid = false;
      errors["cat"] = "Please select product category.";
    }
    if (!fields["brand_id"]) {
      formIsValid = false;
      errors["brand"] = "Please select product brand.";
    }
    if (this.state.multiSelectCategories.length === 0) {
      formIsValid = false;
      errors["cat"] = "Please select product category.";
    }
    if (!fields["container_type"]) {
      formIsValid = false;
      errors["container_type"] = "Please select product container type.";
    }
    if (!fields["flavour"]) {
      formIsValid = false;
      errors["flavour"] = "Please enter product flavour.";
    }
    if (!fields["speciality"]) {
      formIsValid = false;
      errors["speciality"] = "Please enter product speciality.";
    }
    if (!fields["caffeine_content"]) {
      formIsValid = false;
      errors["caffeine_content"] = "Please enter product caffeine content.";
    }
    if (!fields["tax_id"]) {
      formIsValid = false;
      errors["tax"] = "Please select GST tax slab.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // Upload Product Image.
  onChangeFile = async (e) => {
    if (e.target.files.length < 6) {
      var formData = new FormData();
      for (var i = 0; i < e.target.files.length; i++) {
        formData.append("photo", e.target.files[i]);
      }
      await this.props.uploadProductImage(formData, this.props.history);
      this.setState({ productImage: this.props.products.imagePath });
      this.setState({ errors: "" });
    } else {
      let errors = {};
      errors["image"] = "Please select up to 5 product images only.";
      this.setState({ errors: errors });
    }
  };

  onSelect = (event, selectedList, selectedItem) => {
    this.setState({
      multiSelectCategories: event,
    });
  };

  onRemove = (event, selectedList, removedItem) => {
    this.setState({
      multiSelectCategories: event,
    });
  };

  getFields = (input, field) => {
    var output = "";
    for (var i = 0; i < input.length; ++i)
      if (i > 0) {
        output = output + "," + input[i][field];
      } else {
        output = input[i][field];
      }
    return output;
  };

  removeProductImage = async (e, image) => {
    const data = {
      id: e,
      image: image,
    };
    await this.props.deleteProductImage(data);
    const id = this.props.match.params.id;
    await this.props.getSubCatList();
    await this.props.getCategories();
    await this.props.getBrands();
    await this.props.getProduct(id);
    var productData = this.props.products.product[0];
    var fields = this.state.fields;
    Object.keys(productData).forEach(function eachKey(key) {
      fields[key] = productData[key];
    });
    this.setState(fields);
    var cat_ids = fields["cat_id"].split(",");
    this.setState({ cat_ids: cat_ids });
    const selectedValues = [];
    for (let i = 0; i < this.state.cat_ids.length; i++) {
      for (let j = 0; j < this.props.categories.subCats.length; j++) {
        if (this.props.categories.subCats[j]["id"] === this.state.cat_ids[i]) {
          selectedValues.push(this.props.categories.subCats[j]);
        }
      }
    }
    this.setState({ multiSelectCategories: selectedValues });
    this.setState({ selectedValue: selectedValues });
  };
  onclick = async (id, name) => {
    confirmAlert({
      message: "Are you sure want to remove this image.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.removeProductImage(id, name),
        },
        {
          label: "No",
          onClick: () => this.imageCancleRemove(),
        },
      ],
    });
  };
  imageCancleRemove = async () => {
    const id = this.props.match.params.id;
    await this.props.getProduct(id);
    await this.props.getSubCatList();
    await this.props.getCategories();
    await this.props.getBrands();
    var productData = this.props.products.product[0];
    var fields = this.state.fields;
    Object.keys(productData).forEach(function eachKey(key) {
      fields[key] = productData[key];
    });
    this.setState(fields);
    var cat_ids = fields["cat_id"].split(",");
    this.setState({ cat_ids: cat_ids });
    const selectedValues = [];
    for (let i = 0; i < this.state.cat_ids.length; i++) {
      for (let j = 0; j < this.props.categories.subCats.length; j++) {
        if (this.props.categories.subCats[j]["id"] === this.state.cat_ids[i]) {
          selectedValues.push(this.props.categories.subCats[j]);
        }
      }
    }
    this.setState({ multiSelectCategories: selectedValues });
    this.setState({ selectedValue: selectedValues });
  };
  //Handle Fom Submit
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      var result = this.getFields(this.state.multiSelectCategories, "id");
      const productData = {
        product_id: this.state.fields["id"],
        name: this.state.fields["name"],
        image: this.state.productImage,
        description: this.state.fields["description"],
        cat_id: result,
        brand_id: this.state.fields["brand_id"],
        tax_id: this.state.fields["tax_id"],
        container_type: this.state.fields["container_type"],
        flavour: this.state.fields["flavour"],
        speciality: this.state.fields["speciality"],
        caffeine_content: this.state.fields["caffeine_content"],
      };
      await this.props.updateProduct(productData, this.props.history);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Header />
              <div className="main-content-container container-fluid px-4">
                <div className="page-header row no-gutters py-4">
                  <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Overview
                    </span>
                    <h3 className="page-title">Edit Product</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-small mb-4">
                      <div className="card-header border-bottom">
                        <h6 className="m-0">Product Details</h6>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item p-3">
                          <div className="row">
                            <div className="col">
                              <form onSubmit={this.handleSubmit}>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feName">Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Product Name"
                                      value={this.state.fields["name"]}
                                      onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["name"]}
                                    </span>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label for="feBrand">Brand</label>
                                    <select
                                      id="brand_id"
                                      name="brand_id"
                                      className="form-control"
                                      value={this.state.fields["brand_id"]}
                                      onChange={this.handleChange}
                                    >
                                      <option value="">Select brand</option>
                                      {this.props.brands.brands.map(
                                        (item, index) => (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["brand"]}
                                    </span>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feLastName">Category</label>
                                    <Multiselect
                                      options={this.props.categories.subCats}
                                      selectedValues={this.state.selectedValue}
                                      onSelect={this.onSelect}
                                      onRemove={this.onRemove}
                                      displayValue="name"
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["cat"]}
                                    </span>
                                  </div>

                                  <div className="form-group col-md-2">
                                    <label for="feFirstName">Image</label>
                                    <input
                                      type="file"
                                      name="productimage"
                                      className="form-control"
                                      onChange={this.onChangeFile}
                                      accept="image/*"
                                      multiple
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["image"]}
                                    </span>
                                  </div>

                                  <div className="form-group col-md-4">
                                    <div className="d-flex">
                                      {this.props.products.images.map(
                                        (image, index) => (
                                          <li
                                            onClick={(e) =>
                                              this.onclick(
                                                image.id,
                                                image.image
                                              )
                                            }
                                          >
                                            <img
                                              src={API_URL + image.image}
                                              alt=""
                                              height="50"
                                              width="50"
                                            />
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feContainerType">
                                      Container Type
                                    </label>
                                    <select
                                      id="container_type"
                                      name="container_type"
                                      className="form-control"
                                      value={
                                        this.state.fields["container_type"]
                                      }
                                      onChange={this.handleChange}
                                    >
                                      <option value="">Select</option>
                                      <option value="Bag">Bag</option>
                                      <option value="Box">Box</option>
                                      <option value="Can">Can</option>
                                      <option value="Bottle">Bottle</option>
                                      <option value="Carton/Coated Paper">
                                        Carton/Coated Paper
                                      </option>
                                      <option value="Wrapper">Wrapper</option>
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["container_type"]}
                                    </span>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label for="feFlavour">Flavour</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="flavour"
                                      name="flavour"
                                      maxLength="50"
                                      placeholder="Product flavour"
                                      value={this.state.fields["flavour"]}
                                      onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["flavour"]}
                                    </span>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feSpeciality">Speciality</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="speciality"
                                      name="speciality"
                                      maxLength="50"
                                      placeholder="Product speciality"
                                      value={this.state.fields["speciality"]}
                                      onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["speciality"]}
                                    </span>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label for="feCaffeineContent">
                                      Caffeine Content
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="caffeine_content"
                                      name="caffeine_content"
                                      maxLength="50"
                                      placeholder="Product caffeine content"
                                      value={
                                        this.state.fields["caffeine_content"]
                                      }
                                      onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["caffeine_content"]}
                                    </span>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feTax">GST Tax</label>
                                    <select
                                      id="tax"
                                      name="tax_id"
                                      className="form-control"
                                      value={this.state.fields["tax_id"]}
                                      onChange={this.handleChange}
                                    >
                                      <option value="">Select Tax Slab</option>
                                      {this.props.taxes.map((item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["tax"]}
                                    </span>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-12">
                                    <label for="feDescription">
                                      Description
                                    </label>
                                    <textarea
                                      className="form-control"
                                      name="description"
                                      onChange={this.handleChange}
                                      id="description"
                                      value={this.state.fields["description"]}
                                      rows="5"
                                    ></textarea>
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["description"]}
                                    </span>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Update
                                </button>
                                &nbsp;
                                <Link
                                  to="/products-list"
                                  className="btn btn-danger"
                                >
                                  Cancel
                                </Link>
                              </form>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Footer/> */}
            </main>
            <ToastContainer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories,
  products: state.products,
  brands: state.brands,
  taxes: state.taxes.activeTaxes,
});
const actionCreators = {
  getCategories: getCategories,
  getProduct: getProduct,
  updateProduct: updateProduct,
  uploadProductImage: uploadProductImage,
  getBrands: getBrands,
  getSubCatList: getSubCatList,
  deleteProductImage: deleteProductImage,
  getActiveTaxes: getActiveTaxes,
};

export default connect(mapStateToProps, actionCreators)(EditProduct);
