import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBDataTable } from "mdbreact";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { Fab } from "react-tiny-fab";
import EditIcon from "@material-ui/icons/Edit";
import {
  API_URL,
  URL_ADMIN_ADD_PANNEL,
  URL_ADMIN_GET_PANNEL,
} from "../../utils/admin-api-url";
import SideBar from "../CommonTemplates/SideBar.js";
import Header from "../CommonTemplates/Header.js";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  getOnboardings,
  deleteOnBoarding,
} from "../../_actions/onBoardingActions.js";
import { deletePanel, getPanel } from "../../_actions/addPanel";

export class Panel extends Component {
  state = {
    readOnly: true,
    showModal: false,
    id: "",
    listPanel: [],
  };

  async componentDidMount() {
    await getPanel.bind(this)();
  }

  handleOpenModal = (id) => {
    this.setState({ showModal: true, id });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleConfirmDelete = async () => {
    this.setState({ showModal: false });
    await deletePanel({ id: this.state.id });
    await getPanel.bind(this)();
  };

  addNew = () => {
    this.props.history.push("add-panel");
  };

  render() {
    const listPanel = this.state.listPanel;
    let displayContent = "";
    let i = 1;
    displayContent = listPanel.map((rowInfo) => ({
      no: i++,
      key: rowInfo.key,
      title: rowInfo.title,
      value: rowInfo.value,
      image: (
        <div>
          {rowInfo.key == null ? (
            ""
          ) : (
            <img src={API_URL + rowInfo.image} height="50" width="50" alt="" />
          )}
        </div>
      ),
      status: (
        <div>
          {rowInfo.status === "1" ? (
            <button className="btn btn-success">Active</button>
          ) : (
            <button className="btn btn-warning">Inactive</button>
          )}
        </div>
      ),
      action: (
        <div>
          <button
            className="btn btn-danger"
            onClick={() => this.handleOpenModal(rowInfo.id)}
          >
            <DeleteRoundedIcon />
          </button>
          &nbsp;
          <Link to={`/add-panel/${rowInfo.id}`} className="btn btn-warning">
            <EditIcon />
          </Link>
        </div>
      ),
    }));

    const data = {
      columns: [
        {
          label: "S.No",
          field: "no",
          sort: "asc",
          width: 150,
        },
        {
          label: "Key",
          field: "key",
          sort: "asc",
          width: 150,
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 150,
        },
        {
          label: "Value",
          field: "value",
          sort: "asc",
          width: 150,
        },
        {
          label: "action",
          field: "action",
          sort: "action",
          width: 150,
        },
      ],
      rows: displayContent,
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Header />
              <div className="main-content-container container-fluid px-4">
                <div class="page-header row no-gutters py-4">
                  <div class="col-12 col-sm-4 text-center text-sm-left ">
                    <span class="text-uppercase page-subtitle">Overview</span>
                    <h3 class="page-title">Panel</h3>
                  </div>
                </div>
                <Fab
                  icon="+"
                  alwaysShowTitle={false}
                  onClick={this.addNew}
                  id="hoverMenu"
                ></Fab>
                <div class="row">
                  <div class="col">
                    <div class="card card-small mb-4">
                      <div class="card-header border-bottom">
                        <h6 class="m-0">Active Panel</h6>
                      </div>
                      <div class="card-body pb-3 text-center">
                        <MDBDataTable striped bordered hover data={data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
              {/* <Footer/> */}
              <ReactModal
                isOpen={this.state.showModal}
                contentLabel="onRequestClose Example"
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.handleCloseModal}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
              >
                <h3>Are you sure delete this onboarding ?</h3>
                <div className="btn-modal-container">
                  <ul>
                    <li>
                      <button
                        className="btn btn-sm btn-warning btn-modal-close"
                        onClick={this.handleCloseModal}
                      >
                        Cancel
                      </button>
                    </li>
                    <li>
                      {""}
                      <button
                        className="btn btn-sm btn-danger btn-modal-confirm"
                        onClick={this.handleConfirmDelete}
                      >
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </ReactModal>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const customStyles = {
  content: {
    color: "darkred",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "#444",
    opacity: "0.9",
  },
};

const mapStateToProps = (state) => ({
  onboardings: state.onboardings,
});
const actionCreators = {
  getOnboardings: getOnboardings,
  deleteOnBoarding: deleteOnBoarding,
};

export default connect(mapStateToProps, actionCreators)(Panel);
