import {
    POST_ADD_TAX_REQUEST, GET_ADD_TAX_SUCCESS, GET_ADD_TAX_FAILURE,
    POST_TAX_REQUEST, GET_TAX_SUCCESS, GET_TAX_FAILURE,
    POST_TAXES_REQUEST, GET_TAXES_SUCCESS, GET_TAXES_FAILURE,
    POST_REMOVE_TAX_REQUEST, GET_REMOVE_TAX_SUCCESS, GET_REMOVE_TAX_FAILURE,
    POST_UPDATE_TAX_REQUEST, GET_UPDATE_TAX_SUCCESS, GET_UPDATE_TAX_FAILURE,
    POST_ACTIVE_TAXES_REQUEST, GET_ACTIVE_TAXES_SUCCESS, GET_ACTIVE_TAXES_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_ACTIVE_TAXES, URL_ADMIN_ADD_TAX, URL_ADMIN_TAXES_LIST,
    URL_ADMIN_GET_TAX, URL_ADMIN_UPDATE_TAX, URL_ADMIN_REMOVE_TAX 
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Get Single Tax
export const getTax = ID => async dispatch => {
    try {
        dispatch({ type: POST_TAX_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_TAX}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        //console.log(response);
        if (response.data.status === true) {
            dispatch({ type: GET_TAX_SUCCESS, payload: response.data.data });
        }  else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_TAX_FAILURE, payload: error });
    }
};

// Update Tax
export const updateTax = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_TAX_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_TAX, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_TAX_SUCCESS, payload: response.data });
            history.push('/taxes-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        }  else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_TAX_FAILURE, payload: error });
    }
};

// Delete Unit.
export const deleteTax = ID => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_TAX_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_REMOVE_TAX}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_TAX_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        }  else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_TAX_FAILURE, payload: error });
    }
};

// Add tax
export const addTax = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_ADD_TAX_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_TAX, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ADD_TAX_SUCCESS, payload: response.data });
            history.push('/taxes-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        }  else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ADD_TAX_FAILURE, payload: error });
    }
};

// Get All taxes list
export const getTaxes = () => async dispatch => {
    try {
        dispatch({ type: POST_TAXES_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_TAXES_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_TAXES_SUCCESS, payload: response.data.data });
        }  else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_TAXES_FAILURE, payload: error });
    }
};

// Get All taxes list
export const getActiveTaxes = () => async dispatch => {
    try {
        dispatch({ type: POST_ACTIVE_TAXES_REQUEST, payload: true });
        const response = await axios.get(URL_ADMIN_ACTIVE_TAXES, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ACTIVE_TAXES_SUCCESS, payload: response.data.data });
        }  else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ACTIVE_TAXES_FAILURE, payload: error });
    }
};


