import {
    POST_ADD_REVIEW_REQUEST, GET_ADD_REVIEW_SUCCESS, GET_ADD_REVIEW_FAILURE,
    POST_REVIEWS_REQUEST, GET_REVIEWS_SUCCESS, GET_REVIEWS_FAILURE,
    POST_REVIEW_REQUEST, GET_REVIEW_SUCCESS, GET_REVIEW_FAILURE,
    POST_REMOVE_REVIEW_REQUEST, GET_REMOVE_REVIEW_SUCCESS, GET_REMOVE_REVIEW_FAILURE,
    POST_UPDATE_REVIEW_REQUEST, GET_UPDATE_REVIEW_SUCCESS, GET_UPDATE_REVIEW_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_ADD_REVIEW, URL_ADMIN_GET_REVIEWS_LIST, URL_ADMIN_GET_SINGLE_REVIEW,
    URL_ADMIN_UPDATE_REVIEW, URL_ADMIN_DELETE_REVIEW
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Add Review
export const addReview = (reviewData, history) => async dispatch => {
    try {
        dispatch({ type: POST_ADD_REVIEW_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_REVIEW, reviewData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ADD_REVIEW_SUCCESS, payload: response.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            history.push('/reviews-list');
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ADD_REVIEW_FAILURE, payload: error });
    }
};

// Get All reviews list
export const getReviews = () => async dispatch => {
    try {
        dispatch({ type: POST_REVIEWS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_REVIEWS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REVIEWS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REVIEWS_FAILURE, payload: error });
    }
};

// Get Single Review
export const getReview = ID => async dispatch => {
    try {
        dispatch({ type: POST_REVIEW_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_REVIEW}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REVIEW_SUCCESS, payload: response.data.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REVIEW_FAILURE, payload: error });
    }
};

// Update Review
export const updateReview = (stockData, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_REVIEW_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_REVIEW, stockData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_REVIEW_SUCCESS, payload: response.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            history.push('/reviews-list');
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_REVIEW_FAILURE, payload: error });
    }
};

// Delete Review.
export const deleteReview = ID => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_REVIEW_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_DELETE_REVIEW}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_REVIEW_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_REVIEW_FAILURE, payload: error });
    }
};
