import {
    POST_ONBOARDING_ADD_REQUEST, GET_ONBOARDING_ADD_SUCCESS, GET_ONBOARDING_ADD_FAILURE,
    POST_ONBOARDINGS_REQUEST, GET_ONBOARDINGS_SUCCESS, GET_ONBOARDINGS_FAILURE,
    POST_ONBOARDING_REQUEST, GET_ONBOARDING_SUCCESS, GET_ONBOARDING_FAILURE,
    POST_REMOVE_ONBOARDING_REQUEST, GET_REMOVE_ONBOARDING_SUCCESS, GET_REMOVE_ONBOARDING_FAILURE,
    POST_UPDATE_ONBOARDING_REQUEST, GET_UPDATE_ONBOARDING_SUCCESS, GET_UPDATE_ONBOARDING_FAILURE,
    POST_ONBOARDING_IMAGE_REQUEST, GET_ONBOARDING_IMAGE_SUCCESS, GET_ONBOARDING_IMAGE_FAILURE
} from './_type';
import {
    URL_ADMIN_ADD_ONBOARDING, URL_ADMIN_GET_SINGLE_ONBOARDING, URL_ADMIN_GET_ONBOARDINGS_LIST,
    URL_ADMIN_UPDATE_ONBOARDING, URL_ADMIN_UPLOAD_ONBOARDING_IMAGE, URL_ADMIN_REMOVE_ONBOARDING
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Add banner.
export const addOnBoarding = (onBoardingData, history) => async dispatch => {
    try {
        dispatch({ type: POST_ONBOARDING_ADD_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_ONBOARDING, onBoardingData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ONBOARDING_ADD_SUCCESS, payload: response.data });
            history.push('/onboarding-list');
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ONBOARDING_ADD_FAILURE, payload: error });
    }
};

// Get Banner
export const getOnBoarding = (ID) => async dispatch => {
    try {
        dispatch({ type: POST_ONBOARDING_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_ONBOARDING}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ONBOARDING_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ONBOARDING_FAILURE, payload: error });
    }
};

// Get Brand list
export const getOnboardings = () => async dispatch => {
    try {
        dispatch({ type: POST_ONBOARDINGS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_ONBOARDINGS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ONBOARDINGS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ONBOARDINGS_FAILURE, payload: error });
    }
};

// Update Brand
export const updateOnBoarding = (onBoardingData, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_ONBOARDING_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_ONBOARDING, onBoardingData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_ONBOARDING_SUCCESS, payload: response.data });
            history.push('/onboarding-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_ONBOARDING_FAILURE, payload: error });
    }
};

// Remove Brand
export const deleteOnBoarding = (ID, history) => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_ONBOARDING_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_REMOVE_ONBOARDING}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_ONBOARDING_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_ONBOARDING_FAILURE, payload: error });
    }
};

// Upload Brand Image
export const uploadOnboardingImage = (brandImage) => async dispatch => {
    try {
        dispatch({ type: POST_ONBOARDING_IMAGE_REQUEST, payload: true });
        const responseLogo = await axios.post(URL_ADMIN_UPLOAD_ONBOARDING_IMAGE, brandImage, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (responseLogo.data.status === true) {
            dispatch({ type: GET_ONBOARDING_IMAGE_SUCCESS, payload: responseLogo.data });
        } else {
            toast.error(responseLogo.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_ONBOARDING_IMAGE_FAILURE, payload: error });
    }
};
