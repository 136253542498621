import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../../utils/admin-api-url';
import { reactLocalStorage } from 'reactjs-localstorage';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import { getUser, updateUser } from '../../_actions/userActions.js';
import { uploadUserImage } from '../../_actions/authActions.js';

class UserProfile extends Component {
    state = { 
        fields      : {},
        errors      : {},
        userImage   : ''
    }

    async componentDidMount() {
        const id = (reactLocalStorage.getObject('Auth').id);
        await this.props.getUser(id);
        var userdata = this.props.users.user[0];
        var fields = this.state.fields;
        Object.keys(userdata)
            .forEach(function eachKey(key) {               
                fields[key] = userdata[key];                   
            });
        this.setState(fields);
        this.setState({userImage:userdata['profile']});
    }

    //Upload Profile Image.
    onChangeFile = async (e) => {
        var file = e.target.files[0];
        var formData = new FormData();
        formData.append('userImage', file.name);
        formData.append('photo', file);
        await this.props.uploadUserImage(formData);
        this.setState({userImage: this.props.auth.imagePath});
    }

    handleChange = (e) => { 
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value; 
        this.setState(fields);
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields[ 'name' ]) {
            formIsValid = false;
            errors[ 'name' ] = 'Please enter name.';
        }
        if(!fields[ 'phone' ]) {
            formIsValid = false;
            errors[ 'phone' ] = 'Please enter phone.';
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    // Handle Fom Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            const userData = {
                profile   : this.state.userImage,
                user_id   : this.state.fields[ 'id' ],
                name      : this.state.fields[ 'name' ],
                phone     : this.state.fields[ 'phone' ],
            };
            await this.props.updateUser(userData);
        } else {
            toast.error('Please Fill All Required Fields', { position: toast.POSITION.TOP_RIGHT });
        }               
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                                <Header/>
                                <div className="main-content-container container-fluid px-4">
                                    <div className="page-header row no-gutters py-4">
                                        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                            <span class="text-uppercase page-subtitle">OVERVIEW</span>
                                            <h3 className="page-title">User Profile</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="card card-small mb-4 pt-3">
                                                <div className="card-header border-bottom text-center">
                                                    <div className="mb-3 mx-auto">
                                                        <img className="rounded-circle" src={API_URL+this.state.fields['profile']} alt="Avatar" width="110"/>
                                                    </div>
                                                    <h4 className="mb-0">{this.state.fields['name']}</h4>
                                                    <span className="text-muted d-block mb-2">{this.state.fields['type']}</span>
                                                    <input type='file' name='userImage' className='form-control' onChange={ this.onChangeFile } accept='image/*' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="card card-small mb-4">
                                                <div className="card-header border-bottom">
                                                    <h6 className="m-0">Account Details</h6>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item p-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <form onSubmit={this.handleSubmit}>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feFullName">Full Name</label>
                                                                            <input type="text" className="form-control" id="name" name="name" maxLength='50' placeholder="Full Name" value={this.state.fields[ 'name' ]} onChange={this.handleChange}/> 
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'name' ]}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="fePhone">Phone</label>
                                                                            <input type="text" className="form-control" id="phone" maxLength='10' name="phone" placeholder="Phone Number" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }} value={this.state.fields[ 'phone' ]} onChange={this.handleChange}/> 
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'phone' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-12">
                                                                            <label for="feEmailAddress">Email</label>
                                                                            <input type="email" readOnly className="form-control" id="email" name="email" placeholder="Email Address" value={this.state.fields[ 'email' ]} onChange={this.handleChange}/> 
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'email' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary">Update</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            <ToastContainer />     
                        </main>
                    </div>
                </div>
            </React.Fragment>   
        );
    }
}

const mapStateToProps = state => ({
    users       : state.users,
    auth        : state.auth,
});
const actionCreators = {
    getUser         : getUser,
    updateUser      : updateUser,
    uploadUserImage : uploadUserImage,
};
export default connect(mapStateToProps, actionCreators )(UserProfile);
