import {
    POST_PACKET_ADD_REQUEST, GET_PACKET_ADD_SUCCESS, GET_PACKET_ADD_FAILURE,
    POST_PACKETS_REQUEST, GET_PACKETS_SUCCESS, GET_PACKETS_FAILURE,
    POST_PACKET_REQUEST, GET_PACKET_SUCCESS, GET_PACKET_FAILURE,
    POST_REMOVE_PACKET_REQUEST, GET_REMOVE_PACKET_SUCCESS, GET_REMOVE_PACKET_FAILURE,
    POST_UPDATE_PACKET_REQUEST, GET_UPDATE_PACKET_SUCCESS, GET_UPDATE_PACKET_FAILURE,
    POST_ACTIVE_PACKETS_REQUEST, GET_ACTIVE_PACKETS_SUCCESS, GET_ACTIVE_PACKETS_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_ADD_PACKET, URL_ADMIN_GET_SINGLE_PACKET, URL_ADMIN_GET_PACKETS_LIST,
    URL_ADMIN_UPDATE_PACKET, URL_ADMIN_REMOVE_PACKET, URL_ADMIN_GET_ACTIVE_PACKETS
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Add packet
export const addPacket = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_PACKET_ADD_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_PACKET, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_PACKET_ADD_SUCCESS, payload: response.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            history.push('/packets-list');
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_PACKET_ADD_FAILURE, payload: error });
    }
};

// Get All packets list
export const getPackets = () => async dispatch => {
    try {
        dispatch({ type: POST_PACKETS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_PACKETS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_PACKETS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_PACKETS_FAILURE, payload: error });
    }
};

// Get All active packets list
export const getActivePackets = () => async dispatch => {
    try {
        dispatch({ type: POST_ACTIVE_PACKETS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_ACTIVE_PACKETS, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ACTIVE_PACKETS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ACTIVE_PACKETS_FAILURE, payload: error });
    }
};

// Get Single packet
export const getPacket = ID => async dispatch => {
    try {
        dispatch({ type: POST_PACKET_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_PACKET}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_PACKET_SUCCESS, payload: response.data.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_PACKET_FAILURE, payload: error });
    }
};

// Update packet
export const updatePacket = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_PACKET_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_PACKET, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_PACKET_SUCCESS, payload: response.data });
            history.push('/packets-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_PACKET_FAILURE, payload: error });
    }
};

// Delete packet.
export const deletePacket = ID => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_PACKET_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_REMOVE_PACKET}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_PACKET_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_PACKET_FAILURE, payload: error });
    }
};
