//****Base Url ****//

// export const API_URL = 'http://framework.infowindtech.biz:8000/';
// export const API_URL = 'http://dev.server.joycyber.com/';
export const API_URL = "https://dev.server.joycyber.com/";
// export const API_URL = "http://localhost:8000/";
export const BASEASSETURL = "https://dev.server.joycyber.com/public/";
// Auth API.
export const URL_ADMIN_LOGIN = API_URL + "api/admin/login";
export const URL_ADMIN_RESET_PASSWORD = API_URL + "api/admin/forget-password";
export const URL_ADMIN_LOGOUT = API_URL + "api/admin/logout";

// Users API
export const URL_ADMIN_ADD_USER = API_URL + "api/admin/add-user";
export const URL_ADMIN_GET_SINGLE_USER = API_URL + "api/admin/get-user";
export const URL_ADMIN_GET_USERS_LIST = API_URL + "api/admin/get-users-list";
export const URL_ADMIN_DELETE_USER = API_URL + "api/admin/delete-user";
export const URL_ADMIN_UPDATE_USER = API_URL + "api/admin/update-user";
export const URL_ADMIN_CHANGE_USER_PASSWORD =
  API_URL + "api/admin/change-password";
export const URL_ADMIN_UPLOAD_USER_PHOTO =
  API_URL + "api/admin/upload-user-photo";

// Categories API
export const URL_ADMIN_ADD_CATEGORY = API_URL + "api/admin/add-category";
export const URL_ADMIN_GET_CATEGORIES_LIST =
  API_URL + "api/admin/get-categories-list";
export const URL_ADMIN_DELETE_CATEGORY = API_URL + "api/admin/delete-category";
export const URL_ADMIN_GET_SINGLE_CATEGORY = API_URL + "api/admin/get-category";
export const URL_ADMIN_UPDATE_CATEGORY = API_URL + "api/admin/update-category";
export const URL_ADMIN_UPLOAD_CATEGORY_IMAGE =
  API_URL + "api/admin/upload-category-image";

// Products API
export const URL_ADMIN_ADD_PRODUCT = API_URL + "api/admin/add-product";
export const URL_ADMIN_GET_SINGLE_PRODUCT = API_URL + "api/admin/get-product";
export const URL_ADMIN_GET_PRODUCTS_LIST =
  API_URL + "api/admin/get-products-list";
export const URL_ADMIN_GET_ACTIVE_PRODUCTS =
  API_URL + "api/admin/get-active-products";
export const URL_ADMIN_DELETE_PRODUCT = API_URL + "api/admin/delete-product";
export const URL_ADMIN_UPDATE_PRODUCT = API_URL + "api/admin/update-product";
export const URL_ADMIN_GET_PARENT_CAT_LIST =
  API_URL + "api/admin/get-parent-categories";
export const URL_ADMIN_GET_SUB_CATS_LIST =
  API_URL + "api/admin/get-sub-cat-list";
export const URL_ADMIN_UPLOAD_PRODUCT_IMAGE =
  API_URL + "api/admin/upload-product-image";
export const URL_ADMIN_UPDATE_POPULAR_PRODUCT =
  API_URL + "api/admin/update-popular-product";
export const URL_ADMIN_DELETE_PRODUCT_IMAGE =
  API_URL + "api/admin/delete-product-image";

// Address APIs
export const URL_ADMIN_ADD_ADDRESS = API_URL + "api/admin/add-address";
export const URL_ADMIN_GET_SINGLE_ADDRESS = API_URL + "api/admin/get-address";
export const URL_ADMIN_GET_ADDRESSES_LIST =
  API_URL + "api/admin/get-addresses-list";
export const URL_ADMIN_DELETE_ADDRESS = API_URL + "api/admin/delete-address";
export const URL_ADMIN_UPDATE_ADDRESS = API_URL + "api/admin/update-address";

// Stock APIs
export const URL_ADMIN_ADD_STOCK = API_URL + "api/admin/add-stock";
export const URL_ADMIN_GET_SINGLE_STOCK = API_URL + "api/admin/get-stock";
export const URL_ADMIN_GET_STOCKS_LIST = API_URL + "api/admin/get-stocks-list";
export const URL_ADMIN_UPDATE_STOCK = API_URL + "api/admin/update-stock";
export const URL_ADMIN_DELETE_STOCK = API_URL + "api/admin/delete-stock";
export const URL_ADMIN_PRODUCTS_AS_STOCK =
  API_URL + "api/admin/get-products-as-stock";

// Review APIs
export const URL_ADMIN_ADD_REVIEW = API_URL + "api/admin/add-review";
export const URL_ADMIN_GET_SINGLE_REVIEW = API_URL + "api/admin/get-review";
export const URL_ADMIN_GET_REVIEWS_LIST =
  API_URL + "api/admin/get-reviews-list";
export const URL_ADMIN_UPDATE_REVIEW = API_URL + "api/admin/update-review";
export const URL_ADMIN_DELETE_REVIEW = API_URL + "api/admin/delete-review";

// Delivery APIs
export const URL_ADMIN_ADD_DELIVERY = API_URL + "api/admin/add-delivery";
export const URL_ADMIN_GET_SINGLE_DELIVERY = API_URL + "api/admin/get-delivery";
export const URL_ADMIN_GET_DELIVERIES_LIST =
  API_URL + "api/admin/get-deliveries-list";
export const URL_ADMIN_UPDATE_DELIVERY = API_URL + "api/admin/update-delivery";
export const URL_ADMIN_DELETE_DELIVERY = API_URL + "api/admin/delete-delivery";

// Coupon APIs
export const URL_ADMIN_ADD_COUPON = API_URL + "api/admin/add-coupon";
export const URL_ADMIN_GET_SINGLE_COUPON = API_URL + "api/admin/get-coupon";
export const URL_ADMIN_GET_COUPONS_LIST =
  API_URL + "api/admin/get-coupons-list";
export const URL_ADMIN_GET_ALL_USERS_LIST = API_URL + "api/admin/get-user-list";

export const URL_ADMIN_UPDATE_COUPON = API_URL + "api/admin/update-coupon";
export const URL_ADMIN_DELETE_COUPON = API_URL + "api/admin/delete-coupon";
export const URL_ADMIN_ASSIGNED_COUPON_LIST =
  API_URL + "api/admin/get-assigned-coupon-list";
export const URL_ADMIN_CHECK_COUPON = API_URL + "api/admin/delete-coupon";
export const URL_ADMIN_SUBMIT_COUPON = API_URL + "api/admin/assigned-coupon";

// Brand APIs
export const URL_ADMIN_ADD_BRAND = API_URL + "api/admin/add-brand";
export const URL_ADMIN_GET_SINGLE_BRAND = API_URL + "api/admin/get-brand";
export const URL_ADMIN_GET_BRANDS_LIST = API_URL + "api/admin/get-brands-list";
export const URL_ADMIN_UPDATE_BRAND = API_URL + "api/admin/update-brand";
export const URL_ADMIN_REMOVE_BRAND = API_URL + "api/admin/delete-brand";
export const URL_ADMIN_UPLOAD_BRAND_LOGO =
  API_URL + "api/admin/upload-brand-logo";

// Banner APIs
export const URL_ADMIN_ADD_BANNER = API_URL + "api/admin/add-banner";
export const URL_ADMIN_GET_SINGLE_BANNER = API_URL + "api/admin/get-banner";
export const URL_ADMIN_GET_BANNERS_LIST =
  API_URL + "api/admin/get-banners-list";
export const URL_ADMIN_UPDATE_BANNER = API_URL + "api/admin/update-banner";
export const URL_ADMIN_REMOVE_BANNER = API_URL + "api/admin/delete-banner";
export const URL_ADMIN_UPLOAD_BANNER_IMAGE =
  API_URL + "api/admin/upload-banner-image";

// onboarding APIs
export const URL_ADMIN_ADD_ONBOARDING = API_URL + "api/admin/add-onboarding";
export const URL_ADMIN_GET_SINGLE_ONBOARDING =
  API_URL + "api/admin/get-onboarding";
export const URL_ADMIN_GET_ONBOARDINGS_LIST =
  API_URL + "api/admin/get-onboarding-list";
export const URL_ADMIN_UPDATE_ONBOARDING =
  API_URL + "api/admin/update-onboarding";
export const URL_ADMIN_REMOVE_ONBOARDING =
  API_URL + "api/admin/delete-onboarding";
export const URL_ADMIN_UPLOAD_ONBOARDING_IMAGE =
  API_URL + "api/admin/upload-onboarding-image";

// Panel
export const URL_ADMIN_GET_PANNEL = API_URL + "api/settings/get";
export const URL_ADMIN_ADD_PANNEL = API_URL + "api/settings/add";
export const URL_ADMIN_DELETE_PANNEL = API_URL + "api/settings/delete";

// Unit APIs
export const URL_ADMIN_ADD_UNIT = API_URL + "api/admin/add-unit";
export const URL_ADMIN_GET_SINGLE_UNIT = API_URL + "api/admin/get-unit";
export const URL_ADMIN_GET_UNITS_LIST = API_URL + "api/admin/get-units-list";
export const URL_ADMIN_GET_ACTIVE_UNITS =
  API_URL + "api/admin/get-active-units";
export const URL_ADMIN_UPDATE_UNIT = API_URL + "api/admin/update-unit";
export const URL_ADMIN_REMOVE_UNIT = API_URL + "api/admin/delete-unit";

// Packet APIs
export const URL_ADMIN_ADD_PACKET = API_URL + "api/admin/add-packet";
export const URL_ADMIN_GET_SINGLE_PACKET = API_URL + "api/admin/get-packet";
export const URL_ADMIN_GET_PACKETS_LIST =
  API_URL + "api/admin/get-packets-list";
export const URL_ADMIN_GET_ACTIVE_PACKETS =
  API_URL + "api/admin/get-active-packets";
export const URL_ADMIN_UPDATE_PACKET = API_URL + "api/admin/update-packet";
export const URL_ADMIN_REMOVE_PACKET = API_URL + "api/admin/delete-packet";

// Product variant APIs
export const URL_ADMIN_ADD_PRODUCT_VARIANT =
  API_URL + "api/admin/add-product-variant";
export const URL_ADMIN_GET_SINGLE_PRODUCT_VARIANT =
  API_URL + "api/admin/get-product-variant";
export const URL_ADMIN_GET_PRODUCT_VARIANTS_LIST =
  API_URL + "api/admin/get-product-variants-list";
export const URL_ADMIN_UPDATE_PRODUCT_VARIANT =
  API_URL + "api/admin/update-product-variant";
export const URL_ADMIN_REMOVE_PRODUCT_VARIANT =
  API_URL + "api/admin/delete-product-variant";

// Shipping charge API
export const URL_ADMIN_ADD_SHIPPING_CHARGE = API_URL + "api/admin/add-shipping";
export const URL_ADMIN_GET_SINGLE_SHIPPING_CHARGE =
  API_URL + "api/admin/get-shipping";
export const URL_ADMIN_GET_SHIPPING_CHARGES_LIST =
  API_URL + "api/admin/get-shippings-list";
export const URL_ADMIN_UPDATE_SHIPPING_CHARGE =
  API_URL + "api/admin/update-shipping";
export const URL_ADMIN_REMOVE_SHIPPING_CHARGE =
  API_URL + "api/admin/delete-shipping";

// customer help option API
export const URL_ADMIN_ADD_CUST_HELP_OPTION =
  API_URL + "api/admin/add-customer-help-option";
export const URL_ADMIN_GET_SINGLE_CUST_HELP_OPTION =
  API_URL + "api/admin/get-customer-help-option";
export const URL_ADMIN_GET_CUST_HELP_OPTIONS_LIST =
  API_URL + "api/admin/get-customer-help-options-list";
export const URL_ADMIN_UPDATE_CUST_HELP_OPTION =
  API_URL + "api/admin/update-customer-help-option";
export const URL_ADMIN_REMOVE_CUST_HELP_OPTION =
  API_URL + "api/admin/delete-customer-help-option";

// Order API
export const URL_ADMIN_GET_ORDER = API_URL + "api/admin/get-order-detail";
export const URL_ADMIN_GET_ORDERS_LIST = API_URL + "api/admin/get-orders-list";
export const URL_ADMIN_UPDATE_ORDER = API_URL + "api/admin/update-order";
export const URL_ADMIN_REMOVE_ORDER = API_URL + "api/admin/remove-order";

// Customer queries API.
export const URL_ADMIN_GET_ORDER_QUERIES_LIST =
  API_URL + "api/admin/get-queries-list";
export const URL_ADMIN_ORDER_QUERIES = API_URL + "api/admin/get-order-queries";
export const URL_ADMIN_ORDER_QUERY_RESPONSE =
  API_URL + "api/admin/post-order-query-response";

// Taxes API.
export const URL_ADMIN_ACTIVE_TAXES = API_URL + "api/admin/get-active-taxes";
export const URL_ADMIN_ADD_TAX = API_URL + "api/admin/add-tax";
export const URL_ADMIN_TAXES_LIST = API_URL + "api/admin/get-taxes-list";
export const URL_ADMIN_GET_TAX = API_URL + "api/admin/get-tax";
export const URL_ADMIN_UPDATE_TAX = API_URL + "api/admin/update-tax";
export const URL_ADMIN_REMOVE_TAX = API_URL + "api/admin/delete-tax";

//dashboard API.
export const URL_ADMIN_GET_DASHBOARD_DATA =
  API_URL + "api/admin/get-dashboard-data";

// Reports API.
export const URL_ADMIN_EARNING_REPORT =
  API_URL + "api/admin/get-earning-reports";

// notifications APIs
export const URL_ADMIN_ADD_NOTIFICATION = API_URL + "api/admin/add-promo";
export const URL_ADMIN_GET_NOTIFICATION = API_URL + "api/admin/get-promo";
export const URL_ADMIN_UPDATE_NOTIFICATION = API_URL + "api/admin/update-promo";
// notifications APIs
export const URL_ADMIN_ADD_ARTICLE = API_URL + "api/admin/articles-add";
export const URL_ADMIN_UPDATE_ARTICLE = API_URL + "api/admin/articles-update";
export const URL_ADMIN_DELETE_ARTICLE = API_URL + "api/admin/articles-delete";
export const URL_ADMIN_GET_ARTICLE = API_URL + "api/admin/articles";
