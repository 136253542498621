import {
    POST_CUSTOMER_QUERIES_REQUEST, GET_CUSTOMER_QUERIES_SUCCESS, GET_CUSTOMER_QUERIES_FAILURE,
    POST_CUSTOMER_QUERY_REQUEST, GET_CUSTOMER_QUERY_SUCCESS, GET_CUSTOMER_QUERY_FAILURE,
    POST_QUERY_RESPONSE_REQUEST, GET_QUERY_RESPONSE_SUCCESS, GET_QUERY_RESPONSE_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_GET_ORDER_QUERIES_LIST, URL_ADMIN_ORDER_QUERIES, URL_ADMIN_ORDER_QUERY_RESPONSE
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Get customer's queries list.
export const getQueriesList = () => async dispatch => {
    try {
        dispatch({ type: POST_CUSTOMER_QUERIES_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_ORDER_QUERIES_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_CUSTOMER_QUERIES_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_CUSTOMER_QUERIES_FAILURE, payload: error });
    }
};

// Get single customer query.
export const getQueriesOnOrder = ID => async dispatch => {
    try {
        dispatch({ type: POST_CUSTOMER_QUERY_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_ORDER_QUERIES}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_CUSTOMER_QUERY_SUCCESS, payload: response.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_CUSTOMER_QUERY_FAILURE, payload: error });
    }
};

// Post reply of query on a order.
export const postResponse = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_QUERY_RESPONSE_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ORDER_QUERY_RESPONSE, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_QUERY_RESPONSE_SUCCESS, payload: response.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            //history.push('/orders-list');
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_QUERY_RESPONSE_FAILURE, payload: error });
    }
};

