import {
    POST_UPDATE_BRAND_REQUEST, GET_UPDATE_BRAND_SUCCESS, GET_UPDATE_BRAND_FAILURE,
    POST_REMOVE_BRAND_REQUEST, GET_REMOVE_BRAND_SUCCESS, GET_REMOVE_BRAND_FAILURE,
    POST_ADD_BRAND_REQUEST, GET_ADD_BRAND_SUCCESS, GET_ADD_BRAND_FAILURE,
    POST_BRAND_LOGO_REQUEST, GET_BRAND_LOGO_SUCCESS, GET_BRAND_LOGO_FAILURE,
    POST_BRANDS_REQUEST, GET_BRANDS_SUCCESS, GET_BRANDS_FAILURE,
    POST_BRAND_REQUEST, GET_BRAND_SUCCESS, GET_BRAND_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_ADD_BRAND, URL_ADMIN_GET_SINGLE_BRAND, URL_ADMIN_GET_BRANDS_LIST,
    URL_ADMIN_UPDATE_BRAND, URL_ADMIN_REMOVE_BRAND, URL_ADMIN_UPLOAD_BRAND_LOGO
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Add Brand
export const addBrand = (brandData, history) => async dispatch => {
    try {
        dispatch({ type: POST_ADD_BRAND_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_BRAND, brandData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ADD_BRAND_SUCCESS, payload: response.data });
            history.push('/brands-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ADD_BRAND_FAILURE, payload: error });
    }
};

// Get Brand
export const getBrand = (ID) => async dispatch => {
    try {
        dispatch({ type: POST_BRAND_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_BRAND}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_BRAND_SUCCESS, payload: response.data.data });
            //toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_BRAND_FAILURE, payload: error });
    }
};

// Get Brand list
export const getBrands = () => async dispatch => {
    try {
        dispatch({ type: POST_BRANDS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_BRANDS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_BRANDS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_BRANDS_FAILURE, payload: error });
    }
};

// Update Brand
export const updateBrand = (brandData, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_BRAND_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_BRAND, brandData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_BRAND_SUCCESS, payload: response.data });
            history.push('/brands-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_BRAND_FAILURE, payload: error });
    }
};

// Remove Brand
export const deleteBrand = (ID, history) => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_BRAND_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_REMOVE_BRAND}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_BRAND_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.clear('Auth')
            localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_BRAND_FAILURE, payload: error });
    }
};

// Upload Brand Image
export const uploadBrandImage = (brandImage) => async dispatch => {
    try {
        dispatch({ type: POST_BRAND_LOGO_REQUEST, payload: true });
        const responseLogo = await axios.post(URL_ADMIN_UPLOAD_BRAND_LOGO, brandImage, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (responseLogo.data.status === true) {
            dispatch({ type: GET_BRAND_LOGO_SUCCESS, payload: responseLogo.data });
        } else {
            toast.error(responseLogo.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_BRAND_LOGO_FAILURE, payload: error });
    }
};
