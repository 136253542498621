import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBDataTable } from "mdbreact";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { Fab } from "react-tiny-fab";
import EditIcon from "@material-ui/icons/Edit";
import SideBar from "../CommonTemplates/SideBar.js";
import Header from "../CommonTemplates/Header.js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import InfoIcon from "@material-ui/icons/Info";
import {
  getProducts,
  deleteProduct,
  updateProductAsPopular,
} from "../../_actions/productActions.js";

export class ProductList extends Component {
  state = {
    readOnly: true,
    showModal: false,
    id: "",
  };
  async componentDidMount() {
    await this.props.getProducts();
  }

  handleOpenModal = (id) => {
    this.setState({ showModal: true, id });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleConfirmDelete = async () => {
    this.setState({ showModal: false });
    await this.props.deleteProduct(this.state.id);
    await this.props.getProducts();
  };

  addNew = () => {
    this.props.history.push("add-product");
  };

  onclick = async (e, p) => {
    confirmAlert({
      message: "Are you sure want to update product.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handelPopular(e, p),
        },
        {
          label: "No",
          onClick: () => this.locationReload(),
        },
      ],
    });
  };

  locationReload() {
    window.location.reload();
  }

  handelPopular = async (e, p) => {
    if (p === "0") {
      p = "1";
    } else {
      p = "0";
    }
    e = "" + e + "";
    const productData = {
      product_id: e,
      popular: p,
    };
    await this.props.updateProductAsPopular(productData, this.props.history);
    window.location.reload();
  };

  render() {
    const { products } = this.props.products;
    let displayContent = "";
    let i = 1;
    displayContent = products.map((rowInfo) => ({
      no: i++,
      name: rowInfo.name,
      Cat_name: rowInfo.Cat_name,
      brand: rowInfo.Brand_name,
      /* image: 
                <div>
                    <img src={API_URL+rowInfo.image} height='50' width='50' alt='' />
                </div>, */
      //description: rowInfo.description,
      popular: (
        <div>
          {rowInfo.popular === "1" ? (
            <input
              type="checkbox"
              checked
              id={rowInfo.id}
              onClick={(e) => this.onclick(rowInfo.id, rowInfo.popular)}
            />
          ) : (
            <input
              type="checkbox"
              id={rowInfo.id}
              onClick={(e) => this.onclick(rowInfo.id, rowInfo.popular)}
            />
          )}
        </div>
      ),
      price: rowInfo.price + "/-INR",
      discount: rowInfo.discount + "%",
      quantity: rowInfo.quantity,
      action: (
        <div>
          <button
            className="btn btn-danger"
            onClick={() => this.handleOpenModal(rowInfo.id)}
          >
            <DeleteRoundedIcon />
          </button>
          &nbsp;
          <Link to={`/edit-product/${rowInfo.id}`} className="btn btn-warning">
            <EditIcon />
          </Link>
          <Link
            to={`/detail-product/${rowInfo.id}`}
            className="btn btn-primary"
          >
            <InfoIcon />
          </Link>
        </div>
      ),
    }));

    const data = {
      columns: [
        {
          label: "S.No",
          field: "no",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Categories",
          field: "Cat_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Brand",
          field: "brand",
          sort: "asc",
          width: 150,
        },
        /* {
                    label: 'Image',
                    field: 'image',
                    sort: 'asc',
                    width: 150
                }, */
        {
          label: "Popular",
          field: "popular",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: displayContent,
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Header />
              <div className="main-content-container container-fluid px-4">
                <div class="page-header row no-gutters py-4">
                  <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
                    <span class="text-uppercase page-subtitle">Overview</span>
                    <h3 class="page-title">Product List</h3>
                  </div>
                </div>
                <Fab
                  icon="+"
                  alwaysShowTitle={false}
                  onClick={this.addNew}
                ></Fab>
                <div class="row">
                  <div class="col">
                    <div class="card card-small mb-4">
                      <div class="card-header border-bottom">
                        <h6 class="m-0">Active Products</h6>
                      </div>
                      <div class="card-body pb-3 text-center">
                        <MDBDataTable striped bordered hover data={data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
              {/* <Footer/> */}
              <ReactModal
                isOpen={this.state.showModal}
                contentLabel="onRequestClose Example"
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.handleCloseModal}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
              >
                <h3>Are you sure delete this product ?</h3>
                <div className="btn-modal-container">
                  <ul>
                    <li>
                      <button
                        className="btn btn-sm btn-warning btn-modal-close"
                        onClick={this.handleCloseModal}
                      >
                        Cancel
                      </button>
                    </li>
                    <li>
                      {" "}
                      <button
                        className="btn btn-sm btn-danger btn-modal-confirm"
                        onClick={this.handleConfirmDelete}
                      >
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </ReactModal>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const customStyles = {
  content: {
    color: "darkred",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "#444",
    opacity: "0.9",
  },
};

const mapStateToProps = (state) => ({
  products: state.products,
});
const actionCreators = {
  getProducts: getProducts,
  deleteProduct: deleteProduct,
  updateProductAsPopular: updateProductAsPopular,
};

export default connect(mapStateToProps, actionCreators)(ProductList);
