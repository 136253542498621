import {
  POST_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  POST_EARNING_REPORT_REQUEST,
  GET_EARNING_REPORT_SUCCESS,
  GET_EARNING_REPORT_FAILURE,
} from "../_actions/_type";
import {
  URL_ADMIN_GET_DASHBOARD_DATA,
  URL_ADMIN_EARNING_REPORT,
} from "../utils/admin-api-url.js";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import axios from "axios";

// Get All dashboard content
export const getdashboard = () => async (dispatch) => {
  try {
    dispatch({ type: POST_DASHBOARD_REQUEST, payload: true });
    const response = await axios.post(
      URL_ADMIN_GET_DASHBOARD_DATA,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "x-access-token": reactLocalStorage.getObject("access-token"),
        },
      }
    );
    if (response.data.status === true) {
      dispatch({ type: GET_DASHBOARD_SUCCESS, payload: response.data.data });
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      localStorage.clear("Auth");
      localStorage.clear("access-token");
    }
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error });
  }
};

//Get earning report.
export const getEarings = (data) => async (dispatch) => {
  try {
    dispatch({ type: POST_EARNING_REPORT_REQUEST, payload: true });
    const response = await axios.post(
      URL_ADMIN_EARNING_REPORT,
      { data },
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "x-access-token": reactLocalStorage.getObject("access-token"),
        },
      }
    );
    if (response.data.status === true) {
      dispatch({
        type: GET_EARNING_REPORT_SUCCESS,
        payload: response.data.data,
      });
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      localStorage.clear("Auth");
      localStorage.clear("access-token");
    }
  } catch (error) {
    dispatch({ type: GET_EARNING_REPORT_FAILURE, payload: error });
  }
};
