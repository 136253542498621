import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { API_URL } from "../../utils/admin-api-url";
import Header from "../CommonTemplates/Header.js";
import Sidebar from "../CommonTemplates/SideBar.js";
import {
  getUser,
  updateUser,
  uploadUserImage,
} from "../../_actions/userActions.js";

class EditUser extends Component {
  state = {
    readOnly: true,
    fields: {},
    errors: {},
    form_data: {},
    userImage: "",
  };

  async componentDidMount() {
    const id = this.props.match.params.id;
    await this.props.getUser(id);
    var userdata = this.props.user[0];
    var fields = this.state.fields;

    Object.keys(userdata).forEach(function eachKey(key) {
      fields[key] = userdata[key];
    });
    this.setState(fields);
    this.setState({ userImage: fields["profile"] });
  }
  componentDidUpdate(prevProps) {
    if (this.props.users !== prevProps.users) {
      var userdata = this.props.user;
      var fields = this.state.fields;
      Object.keys(userdata).forEach(function eachKey(key) {
        fields[key] = userdata[key];
      });
      this.setState(fields);
    }
  }

  // Upload category image
  onChangeFile = async (e) => {
    var file = e.target.files[0];
    var formData = new FormData();
    formData.append("user", file.name);
    formData.append("photo", file);
    await this.props.uploadUserImage(formData);
    this.setState({ userImage: this.props.imagePath });
  };

  // Input handle
  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState(fields);
  };

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Please enter name.";
    } else if (!fields["name"].match(/^[a-zA-Z ]+$/)) {
      formIsValid = false;
      errors["name"] = "Please enter valid name.";
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Please enter phone.";
    } else if (!fields["phone"].match(/^[6789]\d{9}$/)) {
      formIsValid = false;
      errors["phone"] = "Please valid enter phone number.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // Handle Fom Submit
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      const userData = {
        user_id: this.state.fields["id"],
        profile: this.state.userImage,
        name: this.state.fields["name"],
        phone: this.state.fields["phone"],
        password: this.state.fields["password"],
        type: this.state.fields["type"],
        member: this.state.fields["member"],
      };
      await this.props.updateUser(userData, this.props.history);
    } else {
      toast.error("Please Fill All Required Fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Header />
              <div className="main-content-container container-fluid px-4">
                <div className="page-header row no-gutters py-4">
                  <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Overview
                    </span>
                    <h3 className="page-title">Edit User</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-small mb-4">
                      <div className="card-header border-bottom">
                        <h6 className="m-0">Account Details</h6>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item p-3">
                          <div className="row">
                            <div className="col">
                              <form onSubmit={this.handleSubmit}>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feFirstName">Full Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      placeholder="Full Name"
                                      value={this.state.fields["name"]}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^a-zA-Z ]/g,
                                          ""
                                        );
                                      }}
                                      onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["name"]}
                                    </span>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label for="feFirstName">Phone</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="phone"
                                      name="phone"
                                      maxLength="10"
                                      placeholder="Phone"
                                      value={this.state.fields["phone"]}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                      }}
                                      onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["phone"]}
                                    </span>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feEmailAddress">Email</label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      name="email"
                                      placeholder="Email"
                                      value={this.state.fields["email"]}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label for="feFirstName">
                                      Image(JPEG,PNG,JPG)
                                    </label>
                                    <input
                                      type="file"
                                      name="image"
                                      className="form-control"
                                      onChange={this.onChangeFile}
                                      accept="image/*"
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["image"]}
                                    </span>
                                  </div>
                                  <div className="form-group col-md-3">
                                    <img
                                      src={
                                        API_URL + this.state.fields["profile"]
                                      }
                                      alt=""
                                      height="50"
                                      width="50"
                                    />
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feFirstName">Password</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="password"
                                      name="password"
                                      placeholder="Password"
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^a-zA-Z ]/g,
                                          ""
                                        );
                                      }}
                                      onChange={this.handleChange}
                                    />
                                    {/* <span style={ { color : 'red' } }>{this.state.errors[ 'name' ]}</span> */}
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label for="feFirstName">User Type</label>
                                    <select
                                      id="type"
                                      name="type"
                                      className="form-control"
                                      value={this.state.fields["type"]}
                                      onChange={this.handleChange}
                                    >
                                      <option>Select Type</option>
                                      <option value="admin">ADMIN</option>
                                      <option value="user">USER</option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label for="feFirstName">Member Type</label>
                                    <select
                                      id="member"
                                      name="member"
                                      className="form-control"
                                      value={this.state.fields["member"]}
                                      onChange={this.handleChange}
                                    >
                                      <option>Select Type</option>
                                      <option value="silver">SILVER</option>
                                      <option value="gold">GOLD</option>
                                      <option value="platinum">PLATINUM</option>
                                    </select>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                                &nbsp;
                                <Link
                                  to="/users-list"
                                  className="btn btn-danger"
                                >
                                  Cancel
                                </Link>
                              </form>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Footer/> */}
            </main>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  imagePath: state.users.imagePath,
});
const actionCreators = {
  getUser: getUser,
  updateUser: updateUser,
  uploadUserImage: uploadUserImage,
};

export default connect(mapStateToProps, actionCreators)(EditUser);
